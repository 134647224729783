import classNames from 'classnames'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { investmentPath, investmentsPath } from 'src/paths'
import { Image } from 'src/types'
import * as styles from './company-logo-card.module.scss'

const CompanyLogoCard = ({ uid, image, small }: { uid: string; image: Image; small?: boolean }) => {
  if (!image.fixed) return null

  return (
    <Link
      to={investmentPath(uid)}
      className={classNames(styles.companyLogoCard, { [styles.small]: small })}
      state={{ back: investmentsPath() }}
    >
      <Img fixed={image.fixed} alt={image.alt} />
    </Link>
  )
}
export default CompanyLogoCard
