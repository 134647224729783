import classNames from 'classnames'
import { graphql, Link } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { shuffle } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CompanyLogoCard from 'src/components/company-logo-card'
import NewsletterSignup from 'src/components/newsletter-signup'
import ResponsiveImage from 'src/components/responsive-image'
import { investmentPath, portfolioPath } from 'src/paths'
import { BbvPageProps, Company, PrismicInvestmentsProps } from 'src/types'
import Nyc from '../assets/nyc.svg'
import Layout from '../components/layout'
import * as styles from './investments.module.scss'

type Node = { uid: string; data: Company }

export const query = graphql`
  query InvestmentsQuery {
    prismicInvestments {
      id
      data {
        title {
          raw
        }
        min_amount
        image_header
        companies_header
        bullet_points_header
        bullet_points {
          bullet_point
        }
        image {
          thumbnails {
            large {
              url
              alt
              dimensions {
                height
                width
              }
            }
          }
        }
      }
      tags
    }

    allPrismicCompany(filter: { data: { feature: { eq: true } } }) {
      nodes {
        uid
        data {
          logo {
            fixed(width: 310) {
              ...GatsbyPrismicImageFixed_noBase64
            }
            alt
          }
          summary {
            text
          }
          name {
            raw
            text
          }
          title {
            text
          }
        }
        tags
      }
    }

    prismicHomepage {
      data {
        newsletter_title
        newsletter_description {
          raw
        }
        newsletter_url {
          url
        }
      }
    }

    prismicSettings {
      ...settings
    }
    prismicLinks {
      ...links
    }
  }
`

const CompanyCard = ({ uid, company }: { uid: string; company: Company }) => {
  return (
    <>
      <CompanyLogoCard uid={uid} image={company.logo} />
      <Link
        to={investmentPath(uid)}
        className={classNames(styles.companyCardTitle, 'link-dark h4')}
      >
        {company.title.text}
      </Link>
      <div className={styles.companyCardSummary}>{company.summary.text}</div>
    </>
  )
}

const Investments = (props: BbvPageProps<PrismicInvestmentsProps>) => {
  const [companies, setCompanies] = useState<Node[]>([])

  const homePage = props.data.prismicHomepage.data
  const companyNodes = props.data.allPrismicCompany.nodes
  const investmentsData = props.data.prismicInvestments.data
  const tags = props.data.prismicInvestments.tags.sort((a, b) =>
    a.localeCompare(b, undefined, { sensitivity: 'base' })
  )

  const tagsHalf = Math.ceil(tags.length / 2)
  const splitTags = [tags.slice(0, tagsHalf), tags.slice(tagsHalf, tags.length)]

  useEffect(() => {
    setCompanies(shuffle(companyNodes).slice(0, 3))
  }, [companyNodes])

  return (
    <Layout title="Investments" pageData={props.data}>
      <div className="page-container border-container">
        <Container>
          <h1>Featured Investments</h1>
          <hr />
          <Row>
            {companies.map((node) => (
              <Col xs={12} md={4} key={node.uid} className="mb-4">
                <CompanyCard uid={node.uid} company={node.data} />
              </Col>
            ))}
          </Row>
          <br />
          <Link
            to={portfolioPath()}
            className="btn btn-lg btn-primary text-uppercase btn-block mt-4"
          >
            Full Portfolio
          </Link>
        </Container>
      </div>

      <div className="page-container gray-container pb-4">
        <Container>
          <br />

          <div className={styles.investmentsSection}>
            <h2 className="h1">We have 2 basic criteria</h2>
            <br />
            <Row className={styles.criteriaItems}>
              <Col xs={12} sm={6}>
                <div className={styles.criteriaItem}>
                  <div className={styles.minContainer}>
                    <div className={classNames(styles.lessThan, 'h6-orange-heading')}>
                      Less
                      <br />
                      than
                    </div>
                    <div className={styles.criteriaAmount}>{investmentsData.min_amount}</div>
                  </div>
                  <div className={styles.criteriaItemTitle}>Raised in prior funding rounds.</div>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className={styles.criteriaItem}>
                  <div className={styles.nyc}>
                    <Nyc />
                  </div>
                  <div className={styles.criteriaItemTitle}>Your company is based in NYC.</div>
                </div>
              </Col>
            </Row>
          </div>

          <div className={styles.investmentsSection}>
            {!!investmentsData.bullet_points_header && (
              <h2 className="h1">{investmentsData.bullet_points_header}</h2>
            )}

            {investmentsData.bullet_points.length > 0 && (
              <ul className={styles.bulletPointsList}>
                {investmentsData.bullet_points.map((bulletPoint, index: number) => {
                  return (
                    <li className={styles.bulletPoint} key={index}>
                      {bulletPoint.bullet_point}
                    </li>
                  )
                })}
              </ul>
            )}
          </div>

          {!!investmentsData.image?.thumbnails?.large && (
            <div className={styles.investmentsSection}>
              {!!investmentsData.image_header && (
                <>
                  <h2 className="h1">{investmentsData.image_header}</h2>
                  <br />
                </>
              )}
              <ResponsiveImage image={investmentsData.image?.thumbnails?.large} />
            </div>
          )}
        </Container>
      </div>

      <div className="page-container pb-4">
        <Container>
          <div className={styles.investmentsSection}>
            {!!investmentsData.companies_header && (
              <>
                <br />
                <h2 className="h1">{investmentsData.companies_header}</h2>
                <hr className="mb-4" />
              </>
            )}
            {tags.length > 0 && (
              <>
                <div className={styles.sectorsSubheading}>Select a sector to view investments</div>
                <Row>
                  {splitTags.map((currentTags, index) => (
                    <Col xs={12} md={6} key={index}>
                      {currentTags.map((tag) => (
                        <Link
                          to={portfolioPath(tag)}
                          key={tag}
                          className={classNames(styles.sectorsListItem, 'link-dark')}
                        >
                          {tag}
                        </Link>
                      ))}
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </div>
        </Container>
      </div>

      <NewsletterSignup
        title={homePage.newsletter_title}
        description={homePage.newsletter_description.raw}
        url={homePage.newsletter_url.url}
      />
    </Layout>
  )
}

export default withPreview(Investments)
