import classNames from 'classnames'
import React from 'react'
import { Image } from 'src/types'

const ResponsiveImage = ({
  image,
  alt,
  className,
  skipFixedRatio,
}: {
  image: Image
  alt?: string
  className?: string
  skipFixedRatio?: boolean
}) => {
  const url = image.url
  const width = image?.dimensions?.width
  const height = image?.dimensions?.height
  const ratio = height && width ? (height / width) * 100 : 100

  if (!url) return null

  return (
    <div
      style={{
        width: '100%',
        height: skipFixedRatio ? 'auto' : 0,
        paddingBottom: skipFixedRatio ? 'auto' : `${ratio}%`,
      }}
    >
      <img
        className={classNames([className, 'w-100'])}
        srcSet={`${url}&dpr=1 1x, ${url}&dpr=2 2x, ${url}&dpr=3 3x`}
        src={url}
        alt={alt || image.alt}
      />
    </div>
  )
}

export default ResponsiveImage
