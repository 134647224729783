// extracted by mini-css-extract-plugin
export const companyCardTitle = "investments-module--companyCardTitle--2wHMf";
export const companyCardSummary = "investments-module--companyCardSummary--3gxM9";
export const investmentsSection = "investments-module--investmentsSection--31EFx";
export const criteriaItem = "investments-module--criteriaItem--2ZSF8";
export const lessThan = "investments-module--lessThan--2pwpH";
export const criteriaAmount = "investments-module--criteriaAmount--3FhZ-";
export const criteriaItemTitle = "investments-module--criteriaItemTitle--2o2IM";
export const minContainer = "investments-module--minContainer--2ehyC";
export const nyc = "investments-module--nyc--17gpo";
export const bulletPointsList = "investments-module--bulletPointsList--3MLND";
export const bulletPoint = "investments-module--bulletPoint--19NeT";
export const sectorsSubheading = "investments-module--sectorsSubheading--2irE9";
export const sectorsListItem = "investments-module--sectorsListItem--2EhFw";