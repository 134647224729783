import { RichText, RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import LinkArrow from './link-arrow'
import * as styles from './newsletter-signup.module.scss'

const NewsletterSignup = ({
  title,
  description,
  url,
}: {
  title: string
  description: RichTextBlock[]
  url: string
}) => {
  if (!url) return null

  return (
    <div>
      {url && (
        <div className="page-container gray-container py-0">
          <Container>
            <div className={styles.newsletter}>
              <Row>
                <Col xs={12} md={9}>
                  <div>
                    <div className="h1 mb-1">{title}</div>
                    <div className={styles.newsletterDescription}>
                      <RichText render={description} />
                    </div>
                  </div>
                </Col>

                <Col xs={12} md={3}>
                  <LinkArrow
                    href={url}
                    block={true}
                    textLarge={true}
                    className={styles.newsletterLink}
                    dark={true}
                  >
                    Sign up
                  </LinkArrow>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      )}
    </div>
  )
}

export default NewsletterSignup
